import { AppMenu } from "../../components/bar/appbar"
import { ParallaxComponent } from "../../components/content/parallax"
import image1 from '../../assets/logo/logo_folder_3/logo_white.png'
export function HomePage(){ 
    return (
        <div>
            <AppMenu/>
        </div>

    )

}