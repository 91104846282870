import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link'
import logo from '../../assets/logo/logo_folder_2/Gyasi Consulting-logos_white.png';


export function AppMenu() {
    return (
			<Box>
				<AppBar position="static" sx={{ height: "8vh" , backgroundColor : '#c38d9e' }}>
					<Toolbar>
						<Link href="/about">
							<Box
								component="img"
								sx={{ height: "14vh" }}
								alt="Logo"
								src={logo}
                               
							/>
						</Link>

           
					</Toolbar>
				</AppBar>
			</Box>
		);
}


